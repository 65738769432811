<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title color="white">Nuovo intervento</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="page_content">
        <form @submit.prevent="creaIntervento()">
          <ion-list class="fields">
            <!-- Cliente -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Cliente
                </div>
                <div class="value">
                  {{ printCustomer() }}
                </div>
              </div>
            </ion-item>

            <!-- Progetto -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Progetto
                </div>
                <div class="value">
                  {{ printProject() }}
                </div>
              </div>
            </ion-item>

            <!-- Ticket -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Ticket
                </div>
                <div class="value">
                  {{ printTicket() }}
                </div>
              </div>
            </ion-item>

            <!-- Sede cliente -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Sede
                </div>
                <div class="value">
                  <ion-select name="sede_cliente" interface="action-sheet" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.sede_cliente">
                    <ion-select-option v-for="sede in sedi_cliente" :key="sede.customers_shipping_address_id" :value="sede.customers_shipping_address_id">
                      {{ `${sede.customers_shipping_address_city}, ${sede.customers_shipping_address_street} ` }}
                    </ion-select-option>
                  </ion-select>
                </div>
              </div>
            </ion-item>

            <!-- Data inizio -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Data
                  <ion-text color="danger"><strong>*</strong></ion-text>
                </div>
                <div class="value">
                  <ion-datetime
                    name="data"
                    display-format="DD/MM/YYYY"
                    picker-format="DD/MM/YYYY"
                    min="2022"
                    max="2099"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="intervento.data"
                  ></ion-datetime>
                </div>
              </div>
            </ion-item>

            <!-- Ora inizio -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Ora inizio
                  <ion-text color="danger"><strong>*</strong></ion-text>
                </div>
                <div class="value">
                  <ion-datetime
                    name="ora_inizio"
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    value="1990-02-19T07:43Z"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="intervento.ora_inizio"
                    placeholder="Seleziona ora di inizio"
                  ></ion-datetime>
                </div>
              </div>
            </ion-item>

            <!-- Ora fine -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Ora fine
                  <ion-text color="danger"><strong>*</strong></ion-text>
                </div>
                <div class="value">
                  <ion-datetime
                    name="ora_fine"
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    value="1990-02-19T07:43Z"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="intervento.ora_fine"
                    placeholder="Seleziona ora di inizio"
                  ></ion-datetime>
                </div>
              </div>
            </ion-item>

            <!-- Tempo di viaggio -->
            <ion-item lines="none" class="field ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">
                  Temp di viaggio (min)
                </div>
                <div class="value">
                  <ion-input type="number" min="0" step="any" v-model="intervento.tempo_viaggio"> </ion-input>
                </div>
              </div>
            </ion-item>

            <!-- Ore da fatturare -->
            <ion-item lines="none" class="field ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">Ore da fatturare <small>(lasciare vuoto per calcolo automatico)</small></div>
                <div class="value">
                  <ion-input type="number" min="0" step="any" v-model="intervento.ore_fatturabili"> </ion-input>
                </div>
              </div>
            </ion-item>

            <!-- Tipologia -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Tipo
                </div>
                <div class="value">
                  <ion-select name="tipo" interface="action-sheet" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.tipo">
                    <ion-select-option v-for="tipo in tipologia_intervento" :key="tipo.tickets_reports_type_id" :value="tipo.tickets_reports_type_id">
                      {{ `${tipo.tickets_reports_type_value}` }}
                    </ion-select-option>
                  </ion-select>
                </div>
              </div>
            </ion-item>

            <!-- Stato lavori -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Stato lavori
                  <ion-text color="danger"><strong>*</strong></ion-text>
                </div>
                <div class="value">
                  <ion-select name="stato_lavori" interface="action-sheet" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.stato_lavori">
                    <ion-select-option
                      v-for="stato in stati_lavoro"
                      :key="stato.tickets_reports_stato_lavori_id"
                      :value="stato.tickets_reports_stato_lavori_id"
                    >
                      {{ `${stato.tickets_reports_stato_lavori_value}` }}
                    </ion-select-option>
                  </ion-select>
                </div>
              </div>
            </ion-item>

            <!-- Tecnici -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title">
                  Tecnici
                  <ion-text color="danger"><strong>*</strong></ion-text>
                </div>
                <div class="value">
                  <ion-select name="tecnici" multiple="true" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.tecnici">
                    <ion-select-option v-for="tecnico in tecnici" :key="tecnico.users_id" :value="tecnico.users_id">
                      {{ `${tecnico.users_first_name} ${tecnico.users_last_name}` }}
                    </ion-select-option>
                  </ion-select>
                </div>
              </div>
            </ion-item>

            <!-- Descrizione -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">
                  Descrizione
                </div>
                <div class="value">
                  <ion-textarea type="text" rows="2" v-model="intervento.descrizione" placeholder="Descrizione lavoro svolto" class="custom_input">
                  </ion-textarea>
                </div>
              </div>
            </ion-item>

            <!-- Materiali -->
            <ion-item lines="none" class="ion-no-padding">
              <div class="field">
                <div class="field_title" position="floating">Materiali</div>
                <div class="value">
                  <ion-textarea type="text" rows="3" v-model="intervento.materiali_utilizzati" placeholder="Elenco dei materiali utilizzati"> </ion-textarea>
                </div>
              </div>
            </ion-item>

            <!-- Allegati (foto) intervento-->
            <ion-item lines="none" class="ion-no-padding" v-if="intervento.allegati.length != 0">
              <div class="field">
                <div class="field_title" position="floating">Allegati</div>
                <!--<div class="value">{{ pulizia.foto }}</div> -->
                <div class="foto_container">
                  <div v-for="(foto, index) in intervento.allegati" :key="index" class="single_foto">
                    <ion-thumbnail>
                      <img :src="`data:image/png;base64,${foto.data}`" />
                    </ion-thumbnail>
                    <div @click="deletePhoto(foto.name)" class="remove_photo">Rimuovi</div>
                  </div>
                </div>
              </div>
            </ion-item>

            <div class="action">
              <button type="button" class="btn_allega_foto" @click="openModalPhotos()">
                Carica immagini
              </button>
            </div>

            <div class="actions_firme">
              <button type="button" class="btn_firma" @click="openSignature('cliente')">
                Firma cliente
              </button>
              <button type="button" class="btn_firma" @click="openSignature('tecnico')">
                Firma tecnico
              </button>
            </div>

            <div class="action">
              <button type="submit" class="btn_crea_intervento">
                Salva
              </button>
            </div>
          </ion-list>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  modalController,
  IonList,
  IonItem,
  IonDatetime,
  IonTextarea,
  IonText,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonThumbnail,
  IonIcon,
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";

import { openToast } from "../services/toast";

import apiClienti from "../services/clienti";
import apiInterventi from "../services/interventi";
import apiTecnici from "../services/tecnici";

import moment from "moment";

import ImagesModal from "./ImagesModal";
import ModalFirmaIntervento from "./ModalFirmaIntervento";

export default defineComponent({
  name: "ModalNuovoInterventoFromTicket",
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonIcon,
  },
  setup(props, context) {
    //console.log(props.data);
    const router = useRouter();

    const start_date = new Date();
    const start_hour = new Date();
    const end_hour = new Date();
    start_hour.setHours(start_hour.getHours() - 1);
    end_hour.setHours(end_hour.getHours() + 2);

    const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
    const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

    const intervento = reactive({
      cliente: props.data.value.tickets_customer_id ? props.data.value.tickets_customer_id : null,
      sede_cliente: "",
      progetto: props.data.value.tickets_id ? props.data.value.tickets_id : null,
      ticket: props.data.value.tickets_id ? props.data.value.tickets_id : null,
      data: start_date.toISOString(),
      ora_inizio: start_hour.toISOString(),
      ora_fine: end_hour.toISOString(),
      tempo_viaggio: "",
      ore_fatturabili: "",
      tipo: "",
      stato_lavori: "",
      tecnici: [],
      descrizione: "",
      materiali_utilizzati: "",
      allegati: [],
      firma_tecnico: "",
      firma_cliente: "",
    });

    const successResponse = ref(false);
    const fieldFlag = ref(false); //se tutti i campi sono ok diventa true e posso procedere con la creazione

    /**
     * ! Gestione foto intervento
     */
    const fotoIntervento = ref([]);

    async function openModalPhotos() {
      const modal = await modalController.create({
        component: ImagesModal, //Component to render inside ionic modal
        componentProps: {
          images: fotoIntervento.value,
        },
      });
      modal.onDidDismiss().then((detail) => {
        if (detail.data != undefined) {
          const foto = detail.data;
          if (foto) {
            foto.forEach((singleFoto) => {
              //console.log(singleFoto);
              intervento.allegati.push(singleFoto);
            });
          }
        }
      });
      return modal.present();
    }

    /**
     * ! Remove photo from the fotoInterventi reference data array
     */
    function deletePhoto(photo) {
      intervento.allegati = intervento.allegati.filter((foto) => foto.name !== photo);
    }

    /**
     * ! Open modal to sign
     */
    async function openSignature(signatureType) {
      const modal = await modalController.create({
        component: ModalFirmaIntervento,
        componentProps: {
          data: signatureType,
        },
      });
      modal.onDidDismiss().then((detail) => {
        //console.log(detail);
        if (detail.data) {
          if (detail.data.firma && detail.data.tipo) {
            if (detail.data.tipo === "tecnico") {
              intervento.firma_tecnico = detail.data.firma;
            } else {
              intervento.firma_cliente = detail.data.firma;
            }
          }
        }
      });
      return modal.present();
    }

    /**
     * ! Return customer information
     */
    function printCustomer() {
      if (props.data.value.customers_company) {
        return props.data.value.customers_company;
      } else {
        return `${props.data.value.customers_name} ${props.data.value.customers_last_name}`;
      }
    }
    /**
     * ! Return Project name
     */
    function printProject() {
      if (props.data.value.projects_name) {
        return props.data.value.projects_name;
      } else {
        return "-";
      }
    }
    /**
     * ! Return Ticket title
     */
    function printTicket() {
      if (props.data.value.tickets_subject) {
        return props.data.value.tickets_subject;
      } else {
        return "-";
      }
    }

    /**
     * ! Close modal after form data submit
     */
    async function closeModalOnSubmit(successResponse, res) {
      if (successResponse.value) {
        await modalController.dismiss(res);
      } else {
        await modalController.dismiss();
      }
    }

    /**
     * ! Handle close modal by clicking on Chiudi button
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    const sedi_cliente = ref([]);
    async function loadSediCliente() {
      if (props.data.value.tickets_customer_id) {
        try {
          const res = await apiClienti.getSediCliente(props.data.value.tickets_customer_id);
          sedi_cliente.value = res.data;
        } catch (error) {
          console.error(error);
          openToast("Errore durante la richiesta delle sedi del ciente", "toast_danger");
        }
      }
    }

    const tipologia_intervento = ref([]);
    async function loadTipologieIntervento() {
      try {
        const res = await apiInterventi.getTipologieIntervento();
        tipologia_intervento.value = res;
      } catch (error) {
        console.error(error);
        openToast("Errore durante la richiesta delle tipologie di intervento effettuabili", "toast_danger");
      }
    }

    const stati_lavoro = ref([]);
    async function loadStatiLavoro() {
      try {
        const res = await apiInterventi.getStatoLavori();
        stati_lavoro.value = res;
      } catch (error) {
        console.error(error);
        openToast("Errore durante la richiesta degli stati dei lavori", "toast_danger");
      }
    }

    const tecnici = ref([]);
    async function loadTecnici() {
      try {
        const res = await apiTecnici.getTecnici();
        tecnici.value = res;
      } catch (error) {
        console.error(error);
        openToast("Errore durante la richiesta dei tecnici", "toast_danger");
      }
    }

    /**
     * ! Create new intervento
     */
    async function creaIntervento() {
      const tecnico_id = userID; //set technician as logged user id
      //Controllo che ci sia almeno un tecnico selezioanto
      if (intervento.tecnici.length === 0) {
        openToast("Non puoi creare un intervento senza selezionare i tecnici", "toast_danger");
        return;
      }
      //Controllo di essere sempre tra i tecnici selezionati
      const isUSerSelected = intervento.tecnici.includes(userID);
      if (!isUSerSelected && intervento.tecnici.length != 0) {
        openToast("Non puoi creare un intervento senza essere tra i tecnici selezionati", "toast_danger");
        return;
      }
      //Controllo che ora inizio non sia successiva ad ora fine
      const ora_inizio = new Date(intervento.ora_inizio);
      const ora_fine = new Date(intervento.ora_fine);
      if (ora_inizio > ora_fine) {
        openToast("L'ora di inizio non può essere maggiore dell'ora di fine", "toast_danger");
        return;
      }
      //Controllo che stato lavori sia selezioanto
      if (!intervento.stato_lavori) {
        openToast("Lo stato dei lavori è obbligatorio", "toast_danger");
        return;
      }

      const data_inizio_formatted = moment(intervento.data).format("YYYY-MM-DD");
      const ora_inizio_formatted = moment(intervento.ora_inizio).format("HH:mm");
      const ora_fine_formatted = moment(intervento.ora_fine).format("HH:mm");

      const data = new FormData();
      data.append("tickets_reports_technician", tecnico_id); //logged user
      data.append("tickets_reports_customer_id", props.data.value.tickets_customer_id ? props.data.value.tickets_customer_id : null);
      data.append("tickets_reports_project_id", props.data.value.tickets_project_id ? props.data.value.tickets_project_id : null);
      data.append("tickets_reports_ticket_id", props.data.value.tickets_id ? props.data.value.tickets_id : null);
      data.append("tickets_reports_date", data_inizio_formatted);
      data.append("tickets_reports_start_time", ora_inizio_formatted);
      data.append("tickets_reports_end_time", ora_fine_formatted);
      data.append("tickets_reports_travel_time", intervento.tempo_viaggio);
      data.append("tickets_reports_billable_hours", intervento.ore_fatturabili);
      data.append("tickets_reports_type", intervento.tipo);
      data.append("tickets_reports_stato_lavori", intervento.stato_lavori);
      intervento.tecnici.forEach((tecnico) => {
        data.append("tickets_reports_tecnici[]", tecnico);
      });
      data.append("tickets_reports_description", intervento.descrizione);
      data.append("tickets_reports_used_equipments", intervento.materiali_utilizzati);

      if (intervento.allegati.length != 0) {
        intervento.allegati.forEach((foto) => {
          data.append("tickets_reports_attachments[]", foto.data);
        });
      }

      if (intervento.firma_tecnico) {
        data.append("tickets_reports_firma_operatore_b64", intervento.firma_tecnico);
      }
      if (intervento.firma_cliente) {
        data.append("tickets_reports_firma_cliente_b64", intervento.firma_cliente);
      }

      try {
        const response = await apiInterventi.saveIntervento(data);
        if (response.data.status === 8) {
          openToast(response.data.message, "toast_danger");
        } else {
          const res = response.data.data[0];
          successResponse.value = true;
          closeModalOnSubmit(successResponse, res);
        }
      } catch (error) {
        console.error(error);
        openToast("Errore durante la creazione dell'intervento", "toast_danger");
      }
    }

    onMounted(() => {
      loadSediCliente();
      loadTipologieIntervento();
      loadStatiLavoro();
      loadTecnici();
    });

    return {
      closeModal,
      arrowBackOutline,
      close,
      creaIntervento,
      intervento,
      sedi_cliente,
      tipologia_intervento,
      stati_lavoro,
      tecnici,
      printCustomer,
      printProject,
      printTicket,
      //Allegati (foto)
      openModalPhotos,
      deletePhoto,
      //Firme
      openSignature,
    };
  },
});
</script>

<style scoped>
ion-toolbar {
  --background: #086fa3;
  --color: #ffffff;
}
.page_content {
  width: 100%;
  position: relative;
  min-height: 100%;
  background: #f8fafc;
  padding: 16px;
}

.fields {
  width: 100%;
  border-radius: 4px;
  /*padding: 10px;*/
  padding: 16px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 8px;
}

.field_title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #6b7280;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --padding-left: 0;
  --padding-right: 0;
  --inner-padding-end: 0;
  --ripple-color: transparent;
  --background-activated: transparent;
  --background-activated-opacity: 0;
  margin-bottom: 4px;
}

ion-datetime,
ion-select {
  max-width: 100%;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  --padding-start: 8px;
}

ion-input,
ion-textarea {
  --placeholder-color: #6b7280;
  --padding-start: 8px !important;
  --padding-end: 8px !important;
  margin-top: 0;
  border: 1px solid #d1d5db;
  border-radius: 4px;
}
.custom_input textarea::placeholder {
  font-size: 5px;
}

.btn_crea_intervento {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  margin-bottom: 8px;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
  background-color: rgb(22 163 74);
  color: #ffffff;
}
ion-button {
  --color: #ffffff;
}

.actions_firme {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
}
.btn_firma {
  width: 47%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  background-color: rgb(226 232 240);
  color: rgb(148 163 184);
}

/** Allegati */
.btn_allega_foto {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  background-color: #086fa3;
  color: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
  transition: all 0.15s ease-in;
}

.foto_container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  margin-top: 8px;
  margin-bottom: 16px;
}
.single_foto {
  margin-right: 16px;
  margin-bottom: 16px;
}
ion-thumbnail {
  --size: 120px;
  --border-radius: 4px;
}

.remove_photo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  background-color: rgb(254 226 226);
  color: rgb(239 68 68);
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
}
</style>
